<template>
	<div>
		<!-- eslint-disable -->
		<BackButton :route="{ name: 'administration-users', label: 'Users' }" />

		<div class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Create User
				</h2>
			</div>
		</div>

		<div class="bg-white border-t border-gray-200 shadow sm:rounded-lg">
			<div class="px-6 py-5">
				<div class="mb-3">
					<h3 class="text-lg leading-6 font-medium text-gray-900">
						Profile
					</h3>
					<p class="mt-1 max-w-2xl text-sm text-gray-500">
						This information will be used for authorization and auditing purposes.
					</p>
				</div>

				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
					<div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
						<label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Name
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-3">
							<div class="max-w-lg flex rounded-md shadow-sm">
								<input
									v-model="user.name"
									type="text"
									name="name"
									id="name"
									autocomplete="name"
									class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
								/>
							</div>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
						<label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Username
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-3">
							<div class="max-w-lg flex rounded-md shadow-sm">
								<input
									v-model="user.username"
									type="text"
									name="username"
									id="username"
									autocomplete="username"
									class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
								/>
							</div>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
						<label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Email
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-3">
							<div class="max-w-lg flex rounded-md shadow-sm">
								<input
									v-model="user.email"
									type="email"
									name="email"
									id="email"
									autocomplete="email"
									class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
								/>
							</div>
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
						<label for="password" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Password
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-3">
							<div class="max-w-lg flex rounded-md shadow-sm">
								<input
									v-model="user.password"
									type="password"
									name="password"
									id="password"
									autocomplete="password"
									class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
								/>
							</div>
						</div>
						<label
							for="password_confirmation"
							class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
						>
							Confirm Password
						</label>
						<div class="mt-1 sm:mt-0 sm:col-span-3">
							<div class="max-w-lg flex rounded-md shadow-sm">
								<input
									v-model="user.password_confirmation"
									type="password"
									name="password_confirmation"
									id="password_confirmation"
									autocomplete="password_confirmation"
									class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white border-t border-gray-200 shadow sm:rounded-lg mt-5">
			<div class="px-6 py-5">
				<div class="mb-3">
					<h3 class="text-lg leading-6 font-medium text-gray-900">
						Roles
					</h3>
					<p class="mt-1 max-w-2xl text-sm text-gray-500">
						This information will be used for action authorization.
					</p>
				</div>

				<div v-for="role in user.roles" :key="role.id" class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
					<div class="relative flex items-start">
						<div class="flex items-center h-5">
							<input
								v-model="role.enabled"
								:id="role.id"
								:name="role.id"
								type="checkbox"
								class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded disabled:opacity-50"
								:disabled="role.name === 'Super Admin'"
							/>
						</div>
						<div class="ml-3 text-sm">
							<label :for="role.id" class="font-medium text-gray-700">{{ role.name }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="p-5 flex justify-end">
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
				@click="store"
			>
				<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Save
			</button>
		</div>
	</div>
</template>

<script>
import { inject, computed, ref } from 'vue'
import { useToast } from 'vue-toastification'

import {
	// PlusIcon,
	// XIcon,
	// PencilIcon,
	// SwitchVerticalIcon,
	SaveIcon,
} from '@heroicons/vue/solid'

export default {
	components: {
		// PlusIcon,
		// XIcon,
		// PencilIcon,
		// SwitchVerticalIcon,
		SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			id: this.$route.params.id || null,
			user: {
				name: null,
				username: null,
				email: null,
				password: null,
				password_confirmation: null,
				roles: [],
			},
			roles: [],
			editing: false,
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	computed: {},
	watch: {
		'user.password': function(val) {
			if (val === '') {
				delete this.user.password
				delete this.user.password_confirmation
			}
		},
		'user.password_confirmation': function(val) {
			if (val === '') {
				delete this.user.password_confirmation
			}
		},
	},
	mounted() {
		this.fetchRoles()
	},
	methods: {
		fetchRoles() {
			this.loaderShow()

			this.api.roles
				.all()
				.then(roles => {
					this.roles = roles

					this.roles.forEach(role => {
						role.enabled = false
						this.user.roles.push(role)
					})
				})
				.catch(error => {
					this.toast.error('Error fetching Roles:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		store() {
			this.loaderShow()

			this.api.users
				.store(this.user)
				.then(user => {
					this.$router.push({ name: 'administration-users-id', params: { id: user.id } })

					this.toast.success('Successfully created User')
				})
				.catch(error => {
					let validationErrors = ''
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						validationErrors = error.response.data.message + '\n'
					}
					this.toast.error('Error creating user!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
